export const SITE_NAME = 'Outside Learn';
export const ANALYTICS_DOMAIN = 'learn';
export const VIDEO_PLAYER_ID = 'geVhVkd9';
export const ANONYMOUS_USER_ID = 'fb95b2ad-6e3a-438b-ac44-e8b238eba3ea';
export const COURSE_INTRODUCTION = '/[courseName]';
export const PLACEHOLDER_IMAGE =
  'https://live-pom-oed.pantheonsite.io/wp-content/uploads/2022/03/outside-placeholder.png?crop=16:9&width=450';
export const APPLICATION_URL = 'https://learn.outsideonline.com';
export const FASTLY_PARAMS = 'format=pjpg&auto=webp&quality=85,75';

export const OUTSIDE_ONLINE_BASE_URL = 'https://www.outsideonline.com';
export const OUTSIDE_WATCH_BASE_URL = 'https://watch.outsideonline.com';
export const OUTSIDE_ONLINE_URLS = {
  OUTSIDE_PLUS: `${OUTSIDE_ONLINE_BASE_URL}/?stp=true`,
  BILLING: `${OUTSIDE_ONLINE_BASE_URL}/#_membership/payment/billing`
};

export const PRIVACI_COOKIE_CONSENT_GENERATED =
  '__privaci_cookie_consent_generated';
export const PRIVACI_COOKIE_CONSENT_UUID = '__privaci_cookie_consent_uuid';
export const PRIVACI_COOKIE_CONSENTS = '__privaci_cookie_consents';

export const OUTSIDE_INC_URLS = {
  HELP: 'https://help.outsideinc.com/hc/en-us'
};

export const ACCOUNTS_OUTSIDE_ONLINE_BASE_URL =
  process.env.NEXT_PUBLIC_ACCOUNTS_BASE_URL;

export const ACCOUNTS_OUTSIDE_ONLINE_URLS = {
  OUTSIDE_PLUS: `${ACCOUNTS_OUTSIDE_ONLINE_BASE_URL}/purchase-membership`, // This should be used with redirectUrl search param
  PASSWORD_RESET: `${ACCOUNTS_OUTSIDE_ONLINE_BASE_URL}/forgot-password`,
  PROFILE: `${ACCOUNTS_OUTSIDE_ONLINE_BASE_URL}/profile`,
  SETTINGS: `${ACCOUNTS_OUTSIDE_ONLINE_BASE_URL}/settings`,
  SIGN_UP: `${ACCOUNTS_OUTSIDE_ONLINE_BASE_URL}/signup`,
  SUBSCRIPTION: `${ACCOUNTS_OUTSIDE_ONLINE_BASE_URL}/subscription`
} as const;

export const SCOUT_APP_URL = 'https://scout.outsideonline.com';
const BASE = 0;
const INFRONTOF = 1;
export const Z_BACKGROUND = BASE;
export const Z_FOREGROUND = INFRONTOF + Z_BACKGROUND;
export const Z_PAYWALL = INFRONTOF + Z_FOREGROUND;
export const Z_NAVBAR = INFRONTOF + Z_PAYWALL;
export const Z_PAYWALL_OVERLAY = INFRONTOF + Z_NAVBAR;
export const Z_FULLSCREEN_VID = INFRONTOF + Z_PAYWALL_OVERLAY;
export const ErrorPolicy = {
  Ignore: 'ignore',
  None: 'none',
  All: 'all'
};

export const TRIAL_GROUP_COOKIE_NAME = '_pc_trial_group';
